(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: -15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.2,
        },
        576: {
          items: 1.2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 4,
          loop: false,
        },
        1200: {
          items: 4,
          loop: false,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

